import { Link, useStaticQuery, graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import _ from "lodash";
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import InnerHero from "../../components/Shared/innerHero"


function CategoryCard({ category }) {
    return (
        <div className="col-lg-6 mb-4">
            <div className="card h-100">
                <div className="card-header py-3">
                    <h3>{category.title}</h3>
                </div>
                <div className="card-body">
                    <ul>
                        {category.subCategories.map((item) =>
                            <SubCategory key={item.slug} items={item.links} title={item.title} />
                        )}
                    </ul>
                </div>
            </div>
        </div>
    )
}

function SubCategory({ items, title }) {
    return (
        <>
            <li>
                <span className="faq-sub-list-title">{title}</span>
            </li>
            {items.map((item, i) => (
                <ItemLink key={item.slug} item={item} isLast={(items.length - 1) === i} />
            ))}
        </>
    )
}

function ItemLink({ item, isLast }) {
    return (
        <li className={isLast ? "mb-2" : ""}>
            <Link to={`/support/no-surrender/${item.slug}`}>{item.title}</Link>
        </li>
    )
}



function NoSurrender() {
    const gameId = "no-surrender";
    const [linkItems, setLinkItems] = useState([]);

    const data = useStaticQuery(graphql`
query QueryAll {
  categories: allStrapiSupportCategories {
    nodes {
      strapiId
      title
      slug
    }
  }
  subcategories: allStrapiSupportSubcategories {
    group(field: support_category___id) {
      categoryId: fieldValue
      nodes {
        strapiId
        title
        slug
      }
    }
  }
  subCategoryBased: allStrapiSupportPages {
    group(field: support_subcategory___id) {
      subcategoryId: fieldValue
      nodes {
        title
        slug
      }
    }
  }
}
      `
    );

    useEffect(() => {
        const categories = data.categories.nodes;
        const subcategories = data.subcategories.group;
        const result = categories.map((item) => {
            const subCategories = findSubcategoryList(subcategories, item.strapiId);
            item.subCategories = subCategories;
            item.subCategories.map((subItem) => {
                const subcategoryId = subItem.strapiId;
                const linkGroups = data.subCategoryBased.group;
                const linkGroup = _.find(linkGroups, function (o) { return o.subcategoryId == subcategoryId; });
                if (!linkGroup) {
                    subItem.links = [];
                    return []
                }
                const links = linkGroup.nodes;
                subItem.links = links;
                return subItem;
            });
            return item;
        })
        setLinkItems(result);
    }, [data])

    const findSubcategoryList = (subcategories, categoryId) => {
        const out = _.find(subcategories, function (o) { return o.categoryId == categoryId; });
        return out.nodes;
    }

    return (
        <Layout>
            <Seo title="No Surrender Support" />
            <InnerHero title="No Surrender Support" />
            <section className="pt-120 pb-120 position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-header text-center">
                                <span className="section-sub-title">GOT A QUESTION?</span>
                                <h2 className="section-title">We&apos;ve got answers.</h2>
                                <p>
                                    If you have a question or general enquiry, we&rsquo;re here to
                                    help. Complete the form below and we will get back to you as
                                    soon as we can.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-none-40 cmn-accordion">
                        {linkItems.map((category) => (<CategoryCard key={category.slug} category={category} />))}
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default NoSurrender
